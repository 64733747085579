<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <Header></Header>
        <v-card
          v-if="loading"
          tile
          class="row"
          style="width: 100%; height: 200px"
        >
          <loading-circle v-if="loading" />
        </v-card>

        <div v-else>
          <NoCard
            :showAddCard="showAddCard"
            @sucessAddCard="showAddCard = !showAddCard"
          />
          <v-card v-if="!showAddCard" tile class="row" style="width: 100%">
            <v-tabs v-model="tab">
              <v-tab key="patient_request">Patient Requests</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="patient_request">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="patientsRequestsHeaders"
                    :items="patientsRequestsItems"
                    :loading="patientsRequestsLoading"
                    :items-per-page="4"
                  ></v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-card
            v-if="!showAddCard"
            tile
            class="row"
            style="width: 100%; margin-top: 20px"
          >
            <v-tabs v-model="tab">
              <v-tab key="activities">Activities</v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" style="width: 100%">
              <v-tab-item key="activities">
                <v-card-text>
                  <v-data-table
                    class="table-freezes"
                    :headers="activitiesHeaders"
                    :items="activitiesItems"
                    :loading="activitiesLoading"
                    :items-per-page="4"
                  ></v-data-table>
                </v-card-text>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoadingCircle from '@/components/LoadingCircle.vue';
import Header from './Header.vue';
import _ from 'lodash';
import moment from 'moment';
import NoCard from './NoCard.vue';
import { api, auth } from '../../sharedPlugin';

export default {
  props: ['cards'],
  components: {
    LoadingCircle,
    Header,
    NoCard
  },
  data() {
    return {
      showAddCard: false,
      tab: 0,
      activitiesHeaders: [
        { text: 'Date', value: 'created', sortable: false },
        { text: 'Note', value: 'note', sortable: false }
      ],
      activitiesLoading: false,
      activitiesItems: [],
      patientsRequestsLoading: false,
      patientsRequestsItems: [],
      patientsRequestsHeaders: [
        { text: 'Sent Date', value: 'sent_date', sortable: false },
        { text: 'Subject', value: 'subject', sortable: false },
        { text: 'Type', value: 'type', sortable: false },
        { text: 'Sub Type', value: 'sub_type', sortable: false },

        { text: 'Status', value: 'status', sortable: false }
      ],
      loading: false
    };
  },
  computed: {
    ...auth.mapComputed(['user'])
  },
  methods: {
    async getActivities() {
      this.activitiesLoading = true;
      await api.Cryo.getCryoActivities()
        .then((res) => {
          this.activitiesItems = res
            .map((item) => ({
              note: item?.note,
              created: item?.created
                ? moment.utc(item.created).format('MM/DD/YYYY')
                : '',
              id: item.id
            }))
            .filter((item) => !item.id);
        })
        .finally(() => (this.activitiesLoading = false));
    },
    async getPatientRequest() {
      try {
        this.patientsRequestsLoading = true;
        await api.PatientRequests.getPatientRequests(this.user.patientId)
          .then((res) => {
            try {
              this.patientsRequestsItems = res.map((item) => ({
                id: item.id,
                sent_date: item.sent_date
                  ? moment.utc(item.sent_date).format('MM/DD/YYYY')
                  : '',
                status: item.status,
                type: item.type,
                subject: item.subject,
                sub_type: item.sub_type
              }));
            } catch (error) {
              console.log('error:', error);
            }
          })
          .finally(() => (this.patientsRequestsLoading = false));
      } catch (error) {
        console.log('error: 0', error);
      }
    }
  },
  created() {
    if ((this.cards && this.cards.length === 0) || this.cards === null) {
      this.showAddCard = true;
    }

    this.getPatientRequest();
    this.getActivities();
  }
};
</script>

<style>
.table-freezes .v-data-table__wrapper table tbody tr td {
  height: 30px !important;
  font-size: 0.8rem !important;
}
.table-freezes .v-data-table__wrapper .v-data-table-header tr th {
  color: #000000 !important;
}
.v-data-table.row-height-50 td {
  height: 18px !important;
}
</style>
